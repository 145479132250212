(function($) {
  'use strict';

    $(document).ready(function(){
        $('#schoolday-tabs').find('a').click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });

        $('.schoolday-form-link').click(function(e){
            e.preventDefault();
            var date = $(this).data('ref');
            var schooldayFormContainer = $('.schoolday-content').find('#schoolday-form-container');
            var tabId = '#' + schooldayFormContainer.closest('.tab-pane').attr('id') + '-tab';
            $('#schoolday-tabs').find(tabId).tab('show');
            schooldayFormContainer.find('#schoolday-date-select').val(date);
        });

        $('.gallery-box-select').on('change', function(){
            $('.schoolday-gallery-box').hide();
            $('#gallery-box-'+$(this).val()).show();
        });
    });
}(jQuery));
