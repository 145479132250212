
(function($) {
    "use strict";

    $(document).ready(function () {
        var body = $('body');
        var nav_holder = $('.navbar-holder');

        nav_holder.find('.navbar-nav > .dropdown').on('mouseenter', function() {
            if(!$(this).hasClass('show') && $(window).width() > 991) {
                $(this).find('.dropdown-toggle').dropdown('toggle');
            }
        });

        nav_holder.find('.navbar-nav > .dropdown').on('mouseleave', function() {
            if($(this).hasClass('show') && $(window).width() > 991) {
              $(this).find('.dropdown-toggle').dropdown('toggle');
            }
        });

        var checkNavTop = function() {
            var scroll_top = $(window).scrollTop();
            if(scroll_top >= 73) {
                body.css('padding-top', 73 + nav_holder.outerHeight());
                nav_holder.addClass('is-fixed');
            } else {
                body.css('padding-top', '');
                nav_holder.removeClass('is-fixed');
            }
        };
        checkNavTop();

        $(window).on('scroll', function () {
            checkNavTop();
        });

    });

})(jQuery);

